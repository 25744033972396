import React from 'react';
import { Layout, Menu, Typography } from 'antd';
import { HomeOutlined, ProfileOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { useMatch } from 'react-router';
import { Link } from 'react-router-dom';

import { useMobile, useStore } from '../../hooks';
import styles from './navbar.module.css';
import { clearCredentials } from '../../utils/local_storage';

export const Navbar: React.FC = () => {
    const isMobile = useMobile();
    const clearUserData = useStore(store => store.clearUserData);

    const logout = () => {
        clearUserData();
        clearCredentials();
    }

    // Routing Hooks
    const isOverview = useMatch('/');
    const isSettings = useMatch('/settings');

    return (
        <Layout.Sider collapsed={isMobile} className={styles.sider}>
            <Typography.Title className={styles.title}>{isMobile ? "CS" : "Coli Scholar"}</Typography.Title>
            <Menu theme="dark" mode="inline" selectedKeys={[isOverview ? 'overview' : (isSettings ? 'settings' : '')]}>
                <Menu.Item icon={<HomeOutlined />} key="overview">
                    <Link to="/">Overview</Link>
                </Menu.Item>
                <Menu.SubMenu title="Classes" icon={<ProfileOutlined />} key="classes"></Menu.SubMenu>
                <Menu.Item icon={<SettingOutlined />} key="settings" >
                    <Link to="/settings">Settings</Link>
                </Menu.Item>
                <Menu.Item icon={<LogoutOutlined />} key="logout" onClick={logout}>Logout</Menu.Item>
            </Menu>
        </Layout.Sider>
    );
}