import { useQuery } from 'react-query';
import http from 'axios';

import { apiUrl } from '../constants';
import { useStore } from './store';
import { OverviewData } from '../types';

export const useOverview = () => {
    const credentials = useStore(store => store.credentials);
    const cookie = useStore(store => store.cookie);
    const clearCookie = useStore(store => store.clearCookie);
    const setCookie = useStore(store => store.setCookie);

    return useQuery<OverviewData, Error>(
        'overview',
        () => {
            if (!!cookie) {
                return http.get(`${apiUrl}/overview_cookie/${cookie}`)
                    .then(res => res.data.data)
                    .catch((err) => {
                        if (err.message.includes('401') && !!credentials) {
                            clearCookie();
                            return http.get(
                                `${apiUrl}/overview_credentials/${btoa(credentials.username)}/${btoa(credentials.password)}/${credentials.schoolId}`
                            ).then(res => {
                                setCookie(res.data.cookie);
                                return res.data.data;
                            })
                        } else {
                            return err;
                        }
                    })
            } else if (!!credentials) {
                return http.get(
                    `${apiUrl}/overview_credentials/${btoa(credentials.username)}/${btoa(credentials.password)}/${credentials.schoolId}`
                ).then(res => {
                    setCookie(res.data.cookie);
                    return res.data.data;
                })
            } else {
                throw new Error('401');
            }
        },
        {
            refetchOnMount: false,
        }
    );
}