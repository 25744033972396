import { useMutation, useQueryClient } from 'react-query';
import http, { AxiosResponse } from 'axios';

import { apiUrl } from '../constants';
import { useStore } from './store';
import { OverviewData } from '../types';

interface LoginData {
    cookie: string;
    data: OverviewData;
}

export interface LoginVariables {
    username: string;
    password: string;
    schoolId: string;
}

export const useLogin = () => {
    const queryClient = useQueryClient();
    const setCookie = useStore(state => state.setCookie);
    const setCredentials = useStore(state => state.setCredentials);

    return useMutation<AxiosResponse<LoginData>, Error, LoginVariables>(
        data => http.get(
            `${apiUrl}/overview_credentials/${btoa(data.username)}/${btoa(data.password)}/${data.schoolId}`,
        ),
        {
            onSuccess: ({ data }, vars) => {
                setCookie(data.cookie);
                setCredentials(vars);
                queryClient.setQueryData<OverviewData>('overview', data.data);
            },
            retry: false,
        }
    );
}