import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

export const NotFoundPage = () => {
    const nav = useNavigate();

    return (
            <Result
                status="404"
                title="Page Not Found"
                subTitle="Are you supposed to be here? If so, please email the webmaster with your problem."
                extra={<Button type="primary" onClick={()=>nav('/')}>Return Home</Button>}
            />
    );
}

export default NotFoundPage;
