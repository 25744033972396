import React from 'react';
import { useNavigate } from 'react-router';
import { useStore } from '../hooks';

interface AuthWrapperProps {
    element: React.ReactElement;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ element }) => {
    const navigate = useNavigate();
    const cookie = useStore(store => store.credentials);
    const credentials = useStore(state => state.credentials);

    if (!(cookie || credentials)) {
        console.log("We have")
        navigate('/login');
    }


    return element;
}
