import React from 'react';
import { Layout } from 'antd';

import styles from './footer.module.css';

export const Footer: React.FC = () => {
    return (
        <Layout.Footer className={styles.footer}>
            Created by <a href="https://thecolin.co" rel="noreferrer" target="_blank">Colin C</a> - For Comments, Suggestions, and Complaints Direct Emails <a href={`mailto:${process.env.REACT_APP_WEBMASTER_EMAIL}`} target="_blank" rel="noreferrer">Here</a> - ©2021
        </Layout.Footer>
    );
}