import { LoginVariables } from "../hooks";

export const setCredentials = (credentials: LoginVariables): void => {
    window.localStorage.setItem('username', credentials.username);
    window.localStorage.setItem('password', credentials.password);
    window.localStorage.setItem('schoolId', credentials.schoolId);
}

export const validCredentials = (): boolean => {
    return (
        !!window.localStorage.getItem('username') &&
        !!window.localStorage.getItem('password') &&
        !!window.localStorage.getItem('schoolId')
    );
}

export const getCredentials = (): LoginVariables => {
    return ({
        username: window.localStorage.getItem('username') as string,
        password: window.localStorage.getItem('password') as string,
        schoolId: window.localStorage.getItem('schoolId') as string,
    });
}

export const clearCredentials = (): void => {
    window.localStorage.removeItem('username');
    window.localStorage.removeItem('password');
    window.localStorage.removeItem('schoolId');
}