import create from 'zustand';
import { LoginVariables } from './login';
import { OverviewData } from '../types';

interface Store {
    cookie?: string;
    credentials?: LoginVariables;
    overviewMirror?: OverviewData;
    setCookie: (cookie: string) => void;
    clearCookie: () => void;
    setCredentials: (credentials: LoginVariables) => void;
    clearCredentials: () => void;
    setOverviewMirror: (overviewMirror: OverviewData) => void;
    clearOverviewMirror: () => void;
    clearUserData: () => void;
}

export const useStore = create<Store>(set => ({
    cookie: undefined,
    credentials: undefined,
    overviewMirror: undefined,
    setCookie: (cookie) => set({ cookie }),
    clearCookie: () => set({ cookie: undefined }),
    setCredentials: (credentials) => set({ credentials }),
    clearCredentials: () => set({ credentials: undefined }),
    setOverviewMirror: (overviewMirror) => set({ overviewMirror }),
    clearOverviewMirror: () => set({ overviewMirror: undefined }),
    clearUserData: () => set({
        cookie: undefined, credentials: undefined, overviewMirror: undefined,
    }),
}));
