import React from "react";

export const useMobile = () => {
  const [isMobile, setIsMobile] = React.useState<boolean>(true);

  const updateMobileStatus = () => {
    setIsMobile(window.innerWidth <= 480);
  }

  React.useEffect(() => {
    updateMobileStatus();
    window.addEventListener("resize", updateMobileStatus);

    return () => {
      window.removeEventListener("resize", updateMobileStatus);
    };
  }, []);

  return isMobile;
}