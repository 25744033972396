import React, { Suspense, lazy } from 'react';
import { Routes, Route, useMatch } from 'react-router-dom';
import { Layout } from 'antd';

import styles from './app.module.css';
import { useMobile, useStore } from './hooks';
import { Navbar } from './components/navbar';
import { Footer } from './components/footer';

import NotFoundPage from './pages/404';
import { AuthWrapper } from './utils/route';
import { getCredentials, validCredentials } from './utils/local_storage';
const LoginPage = lazy(() => import('./pages/login'));
const OverviewPage = lazy(() => import('./pages/overview'));
const SettingsPage = lazy(() => import('./pages/settings'));

export const ReactApp: React.FC = () => {
  // Hydrate In-Memory Credentials from Local Storage if Avalible
  const setCredentials = useStore(store => store.setCredentials)
  React.useEffect(() => {
    if (validCredentials()) {
      setCredentials(getCredentials());
    }
  });

  const isMobile = useMobile();
  const isLogin = useMatch('/login');

  return (
    <Layout className={styles.layout}>
      {!isLogin ? <Navbar /> : null}
      <Suspense fallback={<div>Loading</div>}>
        <Layout style={{ marginLeft: !isLogin ? (isMobile ? 80 : 200) : 0 }}>
          <main className={styles.layout}>
            <Routes>
              <Route index element={<AuthWrapper element={<OverviewPage />} />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="settings" element={<AuthWrapper element={<SettingsPage />} />} />
              <Route path="*" element={<AuthWrapper element={<NotFoundPage />} />} />
            </Routes>
          </main>
          <Footer />
        </Layout>
      </Suspense>
    </Layout>
  );
}

export default ReactApp;
